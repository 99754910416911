.cust-header {
  position: sticky; 
  top: 0;
  z-index: 1000; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #685454;
  padding: 10px 20px;
  height: auto;
}


.cust-header-left {
  flex: 1;
  display: flex;
  align-items: center;
}

.menu-icon {
  flex: 1;
}

.menu-button {
  background: none;
  border: 1px solid white;
  color: white;
  padding: 8px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.menu-button:hover {
  background-color: white;
  color: black;
}

.search-bar {
  border-radius: 50px;
  width: 230px;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 8px 10px;
}

.search-input {
  border: none;
  outline: none;
  font-size: 14px;
  background-color: transparent;
  width: 100%; /* Fill the available space */
}

.search-icon {
  font-size: 20px;
  color: #888;
}

.cust-header-center {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cust-header-left .logo img {
  height: 90px;
  width: auto;
}

.collection-menu {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  gap: 17px; /* Increased gap between categories */
}

.collection-link {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: bold;
  text-decoration: none;
  color: #eaeaea;
  font-size: 12.5px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  position: relative; /* Position relative for hover effect */
  transition: color 0.3s ease; /* Smooth color transition */
  padding-bottom: 5px; /* Space for border-bottom effect */
}

.collection-link:hover {
  color: #d6b16f; /* Change color on hover */
}

.collection-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #d6b16f;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.collection-link:hover::after {
  transform: scaleX(1);
}

.collection-link-with-icon {
  display: flex;
  align-items: center;
  cursor: pointer; 
}

.collection-link-with-icon:hover .collection-link {
  color: #d6b16f; 
}

.dropdown-icon {
  font-size: 19px;
  margin-left: 5px;
  color: #eaeaea;
  margin-top: 10px;
}

.cust-header-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #eaeaea;
  margin: 0 10px;
  cursor: pointer; /* Change cursor on hover */
}

/* .header-link:hover {
  color: #6a8c8ec5; 
} */

.header-icon {
  font-size: 24px;
}

.mega-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #ffffff; /* Semi-transparent background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  color: #000000;
  overflow: hidden;
  display: none;
  z-index: 10;
  opacity: 0; /* Start with hidden */
  transition: opacity 0.3s ease; /* Smooth fade-in/out */
}

.mega-menu.active {
  display: flex;
  opacity: 1; /* Fade in when active */
}


.mega-menu-content {
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
}

.mega-menu-content > div {
  flex: 1;
  padding: 0 20px;
}

.categories1,
.categories2,
.categories3 {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.categories1 h3,
.categories2 h3,
.categories3 h3 {
  margin-bottom: 10px;
}

.categories1 div,
.categories2 div,
.categories3 div {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  cursor: pointer; /* Change cursor on hover */
}

.categories1 div:hover,
.categories2 div:hover,
.categories3 div:hover {
  color: #d6b16f; 
  text-decoration: underline; 
}

.menu-image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.menu-image img {
  margin-top: 20px;
  width: 250px;
  height: auto;
}
