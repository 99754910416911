
.home-decor-container {
    display: flex;
    gap: 20px;
    margin: 30px;
  }
  
  /* Section One */
  .section-one {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
  }
  
  .portrait-group {
    display: flex;
    gap: 10px;
  }
  
  .portrait-group .media {
    flex: 1;
    height: 400px;
  }
  
  .portrait-group .media img,
  .portrait-group .media video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .landscape-image1 img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  /* Section Two */
  .section-two {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
  }
  
  .landscape-image2 img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .portrait-content {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  
  .portrait-content .media {
    flex: 1;
    height: 400px;
  }
  
  .portrait-content .media video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .text-content {
    flex: 1;
    max-width: 400px;
  }
  
  .text-content .heading {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .text-content .paragraph {
    font-size: 14px;
    color: #666;
  }
  

  /* Permanent Text Overlay */
.media {
    position: relative; /* Enables positioning the text inside the media */
    overflow: hidden;
}

.media img,
.media video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

/* Text inside images and videos */
.media::after {
    content: attr(data-text); /* Dynamically sets text using the `data-text` attribute */
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    color: white;
    padding: 8px 16px;
    border-radius: 20px;
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
    text-align: center;
    pointer-events: none; /* Makes sure hover effect is not interrupted */
}

/* Hover effect for media (enlarge) */
.media:hover {
    transform: scale(1.015);
    transition: transform 0.3s ease;
}
