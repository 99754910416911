.text-content-section {
    display: flex;
    align-items: flex-start; /* Align items to the top */
    gap: 20px; /* Space between the heading and content */
    margin: 20px;
}

.heading-group {
    flex-shrink: 0; /* Prevents resizing of the heading */
}

.heading-group .main-heading {
    font-size: 100px;
    margin: 10px;
    font-weight: bold;
    color: #333;
}

.content-group {
    margin: 10px;
    width: 650px;
    display: flex;
    flex-direction: column; /* Stack subheading and paragraph */
    margin-left: auto; /* Push content-group to the end */
}

.content-group .sub-heading {
    font-size: 24px;
    font-weight: 500;
    color: #555;
    margin-bottom: 10px;
    text-align: right;
}

.content-group .small-paragraph {
    text-align: right;
    font-size: 16px;
    color: #666;
    line-height: 1.5;
}
