.wall-of-fame {
    text-align: center;
    padding: 50px 20px;
    background-color: #dfd3c3;
}

.wall-of-fame h2 {
    font-size: 36px;
    font-weight: bold;
    color: #f8b400;
    margin-bottom: 5px;
}

.wall-of-fame .subtitle {
    font-size: 18px;
    color: #888;
    margin-bottom: 30px;
}

.fame-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.fame-item {
    position: relative;
    width: 150px;
    height: 150px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.fame-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (min-width: 768px) {
    .fame-item {
        width: 180px;
        height: 180px;
    }
}
