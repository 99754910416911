body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  overflow-x: hidden;
}


.collection-slider-container {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: auto;  /* IE 10+ */
  scrollbar-width: auto;  /* Firefox */
}

.collection-slider-container::-webkit-scrollbar {
  height: 6px; 
  margin: 20px;

}

.collection-slider-container::-webkit-scrollbar-thumb {
  background-color: #000; 
  border-radius: 10px;
  margin: 20px;

}

.collection-slider-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
  margin: 20px;

}


.collection-slider {
  width: 2080px;
  height: 580px;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  border-radius: 20px;
  transition: width 0.3s, height 0.3s;
}

.collection {
  position: relative;
  background-size: cover;
  background-position: center;
  border: 2px solid #000;
  margin: 10px;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 20px;
  transition: width 0.3s, height 0.3s;
}

#bed-runners {
  width: 340px;
  height: 530px;
  background-image: url('https://cdn.shopify.com/s/files/1/0629/1456/0197/files/Topman_Western_Floral_Embroidery_Button-Up_Cardigan_Shirt_in_Light_Blue_at_Nordstrom_Size_Small_1.jpg?v=1721566450');
}

#mattress-protectors {
  width: 280px;
  height: 215px;
  background-image: url('https://cdn.shopify.com/s/files/1/0629/1456/0197/files/The_Alden_Tassel_Moccasin_in_Cordovan.jpg?v=1721566450');
}

#bedsheets {
  width: 280px;
  height: 305px;
  background-image: url('https://cdn.shopify.com/s/files/1/0629/1456/0197/files/lehanga.jpg?v=1721566450');
}

#bedding-sets {
  width: 340px;
  height: 170px;
  background-image: url('https://cdn.shopify.com/s/files/1/0629/1456/0197/files/Crystal_Drop_Earrings.jpg?v=1721566449');
}

#blankets-quilts-dohars {
  width: 340px;
  height: 170px;
  background-image: url('https://cdn.shopify.com/s/files/1/0629/1456/0197/files/banner4.jpg?v=1721566935');
}

#pillows-covers {
  width: 340px;
  height: 170px;
  background-image: url('https://cdn.shopify.com/s/files/1/0629/1456/0197/files/weauty.jpg?v=1721566450');
}

#bed-covers {
  width: 410px;
  height: 530px;
  background-image: url('https://cdn.shopify.com/s/files/1/0629/1456/0197/files/2model.jpg?v=1721566935');
}

#diwan-sets {
  width: 220px;
  height: 225px;
  background-image: url('https://cdn.shopify.com/s/files/1/0629/1456/0197/files/luxury_bedroom_master_makeover_bedroom_inspo_home_bedroom_refresh_bedroom_organization_interior_diy.jpg?v=1721566904');
}

#chair-pads-covers {
  width: 220px;
  height: 295px;
  background-image: url('https://cdn.shopify.com/s/files/1/0629/1456/0197/files/EP_Mode_100_Pure_Cashmere_Throw_Blanket_for_Sofa_Classic_Design_with_Gift_Box_Rugby_Tan.jpg?v=1721566734');
}

#sofa-covers {
  width: 370px;
  height: 530px;
  background-image: url('https://cdn.shopify.com/s/files/1/0629/1456/0197/files/pexels-scottwebb-136418_8d9aeccd-8e2c-4b10-a70a-e4cb83159d90.jpg?v=1721566735');
}


.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
  border-radius: 20px;
}

.collection:hover .overlay {
  opacity: 1;
}

.collection span {
  font-size: 24px;
  margin-bottom: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* opacity: 0; */
  transition: opacity 0.3s;
  border-radius: 20px;
}

.overlay a {
  display: inline-block;
}

.overlay img {
  margin-top: 60px;
  width: 30px;
  height: 30px;
}


.slider {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  padding: 10px 0;
}

.slider::-webkit-scrollbar {
  display: none;
}



/* Responsive Styles */
@media (max-width: 1200px) {
.collection-slider {
      width: 2460px;
      height: 580px;
  }
  #bed-runners{
      width: 340px;
      height: 530px;
  }
  #mattress-protectors {
      width: 280px;
      height: 215px;
  } 
  #bedsheets {
      width: 280px;
      height: 305px;
  }
  #bedding-sets, #blankets-quilts-dohars, #pillows-covers {
      width: 340px;
      height: 170px;
  }
  #bed-covers {
      width: 410px;
      height: 530px;
  }
  #diwan-sets {
      width: 220px;
      height: 225px;
  } 
  #chair-pads-covers {
      width: 220px;
      height: 295px;
  }
  #sofa-covers {
      width: 370px;
      height: 530px;
  }
}

@media (max-width: 980px) {
  .collection-slider {
      width: 2460px;
      height: 550px;
  }
  #bed-runners{
      width: 340px;
      height: 500px;
  }
  #mattress-protectors {
      width: 280px;
      height: 200px;
  } 
  #bedsheets {
      width: 280px;
      height: 290px;
  }
  #bedding-sets, #blankets-quilts-dohars, #pillows-covers {
      width: 340px;
      height: 160px;
  }
  #bed-covers {
      width: 410px;
      height: 500px;
  }
  #diwan-sets {
      width: 220px;
      height: 210px;
  } 
  #chair-pads-covers {
      width: 220px;
      height: 280px;
  }
  #sofa-covers {
      width: 370px;
      height: 500px;
  }
}

@media (max-width: 850px) {
  .collection-slider {
      width: 2290px;
      height: 510px;
  }
  #bed-runners{
      width: 300px;
      height: 460px;
  }
  #mattress-protectors {
      width: 250px;
      height: 180px;
  } 
  #bedsheets {
      width: 250px;
      height: 270px;
  }
  #bedding-sets, #blankets-quilts-dohars, #pillows-covers {
      width: 320px;
      height: 146.6px;
  }
  #bed-covers {
      width: 350px;
      height: 460px;
  }
  #diwan-sets {
      width: 220px;
      height: 170px;
  } 
  #chair-pads-covers {
      width: 220px;
      height: 280px;
  }
  #sofa-covers {
      width: 370px;
      height: 460px;
  }
}

@media (max-width: 768px) {
  .collection-slider {
      width: 2190px;
      height: 480px;
  }
  #bed-runners{
      width: 280px;
      height: 430px;
  }
  #mattress-protectors {
      width: 235px;
      height: 160px;
  } 
  #bedsheets {
      width: 235px;
      height: 260px;
  }
  #bedding-sets, #blankets-quilts-dohars, #pillows-covers {
      width: 290px;
      height: 136.6px;
  }
  #bed-covers {
      width: 320px;
      height: 430px;
  }
  #diwan-sets {
      width: 220px;
      height: 160px;
  } 
  #chair-pads-covers {
      width: 220px;
      height: 260px;
  }
  #sofa-covers {
      width: 370px;
      height: 430px;
  }
}

@media (max-width: 500px) {
  .collection-slider {
      width: 2110px;
      height: 450px;
  }
  #bed-runners{
      width: 250px;
      height: 400px;
  }
  #mattress-protectors {
      width: 220px;
      height: 140px;
  } 
  #bedsheets {
      width: 220px;
      height: 250px;
  } 
  #bedding-sets, #blankets-quilts-dohars, #pillows-covers {
      width: 270px;
      height: 126.6px;
  }
  #bed-covers {
      width: 310px;
      height: 400px;
  }
  #diwan-sets {
      width: 220px;
      height: 140px;
  } 
  #chair-pads-covers {
      width: 220px;
      height: 250px;
  }
  #sofa-covers {
      width: 370px;
      height: 400px;
  }

  .collection span {
    font-size: 21px;
    margin-bottom: 10px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* opacity: 0; */
    transition: opacity 0.3s;
    border-radius: 20px;
}

.overlay img {
  margin-top: 60px;
  width: 26px;
  height: 26px;
}
}