.collection-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px; /* Increased gap between items */
  margin: 15px;
}

.collection-item {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.circle-wrapper {
  width: 100px; /* Space between border and circle */
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #d3d3d3;
  border-radius: 50%;
  padding: 8px; /* Adds space between the circle and the border */
  transition: transform 0.3s ease;
}

.circle-wrapper:hover {
  transform: scale(1.1);
}

.circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.circle-wrapper:hover .circle {
  transform: scale(1.1); /* Zoom-in effect on hover */
}

.category-name {
  font-size: 13px;
  color: #333;
  word-wrap: break-word; /* Allows text to wrap and fit within container */
  max-width: 120px; /* Ensures a fixed width for consistent spacing */
  line-height: 1.2;
  margin-top: 8px; /* Spacing between circle and text */
}
