/* Main container styling */
.main-container {
    margin-top: 20px;
    display: flex;
    height: 80vh; /* Full-screen height */
    /* width: 100vw;  */
  }
  
  /* Content div styling */
  .content-div {
    flex: 1; /* Takes 50% width */
    background-color: #f0e9df; /* Light greyish background */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .big-heading {
    font-size: 40px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .underline {
    width: 80px;
    height: 4px;
    background-color: #6b6558; /* Blue underline */
    border: none;
    margin: 10px 0;
  }
  
  .sub-heading {
    font-size: 24px;
    font-weight: 300;
    color: #555;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .description {
    font-size: 15px;
    color: #666;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .cta-button {
    padding: 10px 20px;
    font-size: 16px;
    color: #6b6558; /* Initial button text color */
    background-color: transparent; /* Transparent background */
    border: 2px solid #6b6558; /* Solid border */
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease; /* Smooth transitions for all properties */
}

.cta-button:hover {
    color: white; /* Change text color on hover */
    background-color: #6b6558; /* Change background color */
    border-color: #b0a690; /* Change border color */
    box-shadow: 0 0 15px rgba(107, 101, 88, 0.7); /* Add a subtle glow effect */
}

.cta-button span {
    position: relative;
    z-index: 1; /* Ensure text stays above the button background */
    transition: color 0.3s ease; /* Smooth text color transition */
}

  
  /* Image div styling */
  .image-div {
    flex: 1;
    background-color: #9a917f;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  
  .centered-image {
    width: 400px;
    height: 500px;
    max-width: 80%;
    max-height: 80%;
    object-fit: cover;
    border-radius: 15px;
    position: absolute;
    transition: opacity 0.5s ease-in-out; /* Smooth transition for opacity */
    opacity: 1; /* Fully visible by default */
  }
  
  .centered-image.fade {
    opacity: 0; /* Fade-out effect */
  }
  
  
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .main-container {
      flex-direction: column; /* Stack sections vertically on smaller screens */
    }
  
    .content-div,
    .image-div {
      flex: none;
      height: 50vh; /* Each section takes 50% of viewport height */
    }
  }
  