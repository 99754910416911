body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #fefdfb;
  color: #333;
}

.features-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  margin: 0 auto;
  background-color: #eaeaea; /* Background color */
  gap: 30px; /* Space between items */
}

.feature-item {
  text-align: center;
  max-width: 260px;
}

.image-item {
  flex: 1 1 20%; /* 1st and 2nd images will take equal space */
}

.content-item {
  flex: 1 1 25%; /* 3 content items will take equal space */
}

.feature-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 160px; /* Rounded corners for images */
  margin-bottom: 10px; /* Space between image and text */
}

.feature-item h3 {
  font-family:cursive;
  font-size: 20px;
  margin-bottom: 10px;
}

.feature-item p {
  font-size: 16px;
  color: #555;
}

@media (max-width: 768px) {
  .features-section {
    flex-direction: column;
    gap: 30px;
  }

  .image-item, .content-item {
    flex: 1 1 100%; /* On smaller screens, items will stack vertically */
  }
}
