.menubar-container {
  background-color: rgba(11, 21, 40, 0.5);
  position: relative;
  padding: 10px;
}

.menubar {
  display: flex;
  flex-wrap: nowrap; 
  background-color:  #8b7863;
  overflow-x: auto; 
  -webkit-overflow-scrolling: touch; 
  scroll-behavior: smooth;
  
}

.menu-item {
  width: 11%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  padding: 10px;
  text-align: center;
  color: #eaeaea;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.3s;
}

.menu-item svg {
  font-size: 36px;
  margin-bottom: 5px;
  color: #eaeaea;
  transition: color 0.3s ease;
}

.menu-item p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #eaeaea;
  transition: color 0.3s ease;
}

.menubar-wrapper {
  position: relative; 
}

.menu-item.active {
  background-color: rgba(255, 255, 255, 0.1);
}

.menu-item.active svg {
  transform: scale(1.2); /* Zoom effect */
}

.menu-item:hover {
  color: #AD6336;
}

.menu-item:hover svg {
  color: #AD6336;
}

.menu-item:hover p {
  color: #ad633d;
}

.dropdown-container {
  position: absolute;
  top: 100%; 
  left: 0;
  width: 100%;
  z-index: 1;
}

.dropdown-menu {
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #0b1528;
  text-align: center;
  transition: opacity 0.2s ease, visibility 0.2s ease;
}

.subcategory-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin-top: 20px;
}

.subcategory-item {
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  color: #ffffff;
  padding: 10px;
  position: relative;
}

.subcategory-item::after {
  content: "";
  position: absolute;
  left: 25%;
  bottom: -2px;
  width: 0%;
  height: 2px;
  background-color: #ad633d;
  transition: width 0.3s ease;
}

.subcategory-item:hover {
  color: #ad633d;
  transform: scale(1.05);
}

.subcategory-item:hover::after {
  width: 50%;
}
