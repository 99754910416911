/* src/pages/customer/homepage/homepage.css */
.homepage {
  text-align: center;
  /* padding: 50px; */
}
  
.homepage h1 {
  font-size: 2em;
  color: #333;
}

body {
  /* background-color: #eaeaea; */
  background-color: #fff;
}

.image-slider-container {
  width: 100%;
  max-width: 1519px;
  /* margin: 20px auto; */
}

.homepgelement2 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.products1, .products2 {
  margin-left: 40px;
}