/* src/styles/cust_footer.css */
.footer {
    background-color: black;
    color: #eaeaea;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding-bottom: 20px;
    margin-top: 40px;
  }
  
  .footer-section {
    max-width: 200px;
    margin-top: 35px;
  }
  
  .logo-section .logo {
    max-width: 100%;
    height: auto;
    cursor: default;
  }
  
  .logo-section p {
    font-size: 15px;
    margin-top: -26px;
  }
  
  .footer-section h3 {
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  .footer-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 5px;
  }
  
  .footer-section ul li a {
    color: #eaeaea;
    text-decoration: none;
  }
  
  .footer-section ul li a:hover {
    text-decoration: underline;
  }
  
  .contact-section p {
    margin: 5px 0;
  }
  
  .contact-section a {
    color: #eaeaea;
    text-decoration: none;
  }
  
  .contact-section a:hover {
    text-decoration: underline;
  }

  span {
    color: #AD6336;
  }
  
  .footer-divider {
    border: 0;
    height: 1px;
    background:#eaeaea;
    margin: 20px 0;
  }
  
  .footer-bottom {
    text-align: center;
    padding: 10px 0;
  }
  
  .footer-bottom p {
    margin: 0;
    margin-bottom: 15px;
  }
  