.image-slider-container {
  width: 100%;
  margin: 0 auto;
}

.slide {
  height: 600px; /* Set the height to match your banner height */
  overflow: hidden;
}

.slider-image {
  width: 100%;
  height: 100%;
  object-fit: cover; 
  border-radius: 130px; 
  padding-left: 30px; 
  padding-right: 30px;
  box-sizing: border-box; 
}

.slick-dots {
  bottom: 10px;
}

.slick-dots li button:before {
  font-size: 10px;
  color: #fff;
  opacity: 0.75;
}

.slick-dots li.slick-active button:before {
  color: chocolate;
  opacity: 1;
}
