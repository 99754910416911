.collection-card-container {
    display: flex;
    justify-content: center; /* Center-align all cards */
    gap: 30px; 
    margin-top: 20px;
    padding: 20px 10px; 
    /* background-color: #8f8787; */
  }
  
  .collection-card {
    background-color: #8f8787; /* Card background color */
    border-radius: 100px; /* Rounded corners for a softer design */
    width: 170px; /* Optimized width for better content balance */
    height: 300px; /* Adjusted height for a compact look */
    padding: 20px; /* Adequate padding inside the card */
    text-align: center; /* Center-align text */
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2); /* Slightly deeper shadow for depth */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Evenly space content vertically */
    transition: transform 0.3s ease; /* Smooth hover effect */
  }
  
  .collection-card:hover {
    transform: scale(1.05); /* Slightly enlarge card on hover */
  }
  
  .card-image-container {
    background-color: #f7f5f0; /* Light background for image area */
    border-radius: 50%; /* Perfectly circular container */
    overflow: hidden;
    height: 160px; /* Optimized circular image size */
    width: 160px;
    margin: 0 auto 15px; /* Center container and add bottom spacing */
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #ddd; /* Add a subtle border for emphasis */
  }
  
  .card-image {
    height: 100%;
    width: 100%;
    object-fit: cover; /* Cover the circular area while maintaining aspect ratio */
  }
  
  .card-title {
    font-size: 1.1rem; /* Slightly increased for better readability */
    font-weight: bold;
    color: #ffffff; /* White text for contrast */
    margin: 5px 0;
  }
  
  .card-number {
    font-size: 0.9rem; /* Slightly reduced size */
    color: #e5e5e5; /* Lighter text color */
    margin: 3px 0;
  }
  
  .card-description {
    font-size: 0.85rem; /* Reduced description text size for compactness */
    color: #eaeaea; /* Lightened text for subtle contrast */
    margin-top: 5px;
  }
  