.homepgelement1 {
  position: relative;
  height: 80vh; /* Full viewport height */
  width: 100%; /* Full width */
  background-size: cover; /* Ensure the image covers the container */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent image repetition */
  display: flex; /* For centering content */
  justify-content: center;
  align-items: center;
  font-family: 'Georgia', serif;
}

.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Slightly transparent overlay */
  /* z-index: 1; */
}

.content {
  position: relative;
  z-index: 2; /* Layer above the overlay */
  text-align: center;
}

.content-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-title {
  font-size: 6rem;
  font-weight: bold;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: white;
  margin: 20px 0;
}

.content-subtitle {
  font-size: 1.5rem;
  color: #ffffff;
  margin: 0px;
  letter-spacing: 2px;
  text-transform: capitalize;
  font-weight: 300;
  animation: fade-in 1.5s ease-in-out;

  /* Set a fixed height to prevent shifting */
  min-height: 2.5rem; /* Adjust based on subtitle text size */
}

/* Fade-in animation for subtitle */
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.border-line {
  width: 80px;
  height: 2px;
  background-color: white;
  margin: 10px 0;
}

@media (max-width: 768px) {
  .content-title {
    font-size: 3rem;
  }

  .border-line {
    width: 50px;
  }

  .content-subtitle {
    min-height: 2rem; /* Adjust for smaller screens */
  }
}
